import * as React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"

// markup
const Skills = () => {
  return (
      <Layout>
        <div>
        <Container showFooter colour="0d1120" title="Skills">
          <div className="">
            <p className="mb-20 max-w-prose text-accent-text">Listed below are both my skills and the tools, languages and frameworks I'm most familiar with.</p>

            <div className="mb-20">
              <h3 className="text-lg font-bold mb-8 text-main-text">Design</h3>

              <h4 className="text-md font-bold mb-4 text-main-text">Skills</h4>

              <div className="grid sm:grid-cols-2 gap-6 mb-8">
                <div>
                  <ul className="list-disc list-inside text-accent-text">
                    <li className="py-2">User research</li>
                    <li className="py-2">UX</li>
                    <li className="py-2">UI</li>
                    <li className="py-2">User testing</li>
                  </ul>
                </div>

                <div>
                  <ul className="list-disc list-inside text-accent-text">
                    <li className="py-2">Facilitating design sprints</li>
                    <li className="py-2">Design Systems</li>
                    <li className="py-2">Accessibility</li>
                  </ul>
                </div>
              </div>

              <h4 className="text-md font-bold mb-4 text-main-text">Tools</h4>

              <div className="grid sm:grid-cols-2 gap-6 mb-8">
                <div>
                  <ul className="list-disc list-inside text-accent-text">
                    <li className="py-2">Figma</li>
                    <li className="py-2">Sketch</li>
                    <li className="py-2">Abstract</li>
                    <li className="py-2">Adobe CC</li>
                  </ul>
                </div>

                <div>
                  <ul className="list-disc list-inside text-accent-text">
                    <li className="py-2">Marvel</li>
                    <li className="py-2">InVision</li>
                    <li className="py-2">Hotjar</li>
                    <li className="py-2">Google Analytics</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="">
              <h3 className="text-lg font-bold mb-8 text-main-text">Development</h3>

              <h4 className="text-md font-bold mb-4 text-main-text">Languages and frameworks</h4>

              <div className="grid sm:grid-cols-2 gap-6 mb-8">
                <div>
                  <ul className="list-disc list-inside text-accent-text">
                    <li className="py-2">HTML/CSS</li>
                    <li className="py-2">Javascript</li>
                    <li className="py-2">React</li>
                  </ul>
                </div>

                <div>
                  <ul className="list-disc list-inside text-accent-text">
                    <li className="py-2">Vue</li>
                    <li className="py-2">Python</li>
                  </ul>
                </div>
              </div>

              <h4 className="text-md font-bold mb-4 text-main-text">Tools</h4>

              <div className="grid sm:grid-cols-2 gap-6">
                <div>
                  <ul className="list-disc list-inside text-accent-text">
                    <li className="py-2">Git</li>
                    <li className="py-2">SASS</li>
                    <li className="py-2">Tailwind</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
        </div>
      </Layout>
  )
}

export default Skills